import { exact } from 'prop-types';
import React from 'react';
import OtpVerification from './Screens/Authentication/OtpVerification';

const Signin1 = React.lazy(() => import('./Screens/Authentication/SignIn'));
const forgotPassword = React.lazy(() => import('./Screens/Authentication/ResetPassword/forgotPassword'));
const resetPassword = React.lazy(() => import('./Screens/Authentication/ResetPassword/resetPassword'));
const Signout = React.lazy(() => import("./Screens/Authentication/SignIn/Signout"));
const AdminSignout = React.lazy(() => import("./Screens/Authentication/SignIn/AdminSignout"));

const Register = React.lazy(() => import("./Screens/Authentication/SignUp/SignUp1"));
const VerifyEmail = React.lazy(() => import("./Screens/Authentication/SignUp/VerifyEmail"));
const PrivacyPolicy = React.lazy(() => import("./Screens/Authentication/privacyPolicy"));
const TermsAndConditions = React.lazy(() => import("./Screens/Authentication/termsAndConditions"));
const SigninAdmin = React.lazy(() => import('./Screens/Authentication/SignIn/indexAdmin'));


const route = [
    { path: '/', exact: true, name: 'Signin 1', component: Signin1 },
    { path: '/admin', exact: true, name: 'Signin 1', component: SigninAdmin },
    { path: '/logout', exact: true, name: 'Signout', component: Signout },
    { path: '/admin/logout', exact: true, name: 'Signout', component: AdminSignout },
    { path: '/forgot-password', exact: true, name: 'Reset Password 1', component: forgotPassword },
    { path: '/reset-password', exact: true, name: 'Reset Password Password', component: resetPassword },
    { path: '/register', exact: true, name: 'Register', component: Register },
    { path: '/verify-email', exact: true, name: 'Verify Email', component: VerifyEmail },
    { path: '/privacy-policy', exact: true, name: 'Privacy Policy', component: PrivacyPolicy },
    { path: '/terms-and-conditions', exact: true, name: 'Terms and conditions', component: TermsAndConditions },
    {path: '/otp-verification', exact: true, name : "OTP Verification", component : OtpVerification},
    
];

export default route;