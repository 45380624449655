import {
    globalVariable,
} from '../utils/global';
import axios from "axios";
import defaultStorage from '../utils/storage'


export const PostDataWithoutToken = async (url,data)  => {
    let BaseUrl = globalVariable.baseURL + url;
    return await axios.post(BaseUrl,data,{
        headers: {
            "Content-Type": "application/json",
        },
    }).then(function (response) {
       //localStorage.setItem("token",response.headers['authorization'])
       //GetCommonData("/common/get-common-data");
       return response.data;
    })
    .catch(function (error) {
        if(error?.response?.data){
            return error.response.data
        }else{
            return error.response
        }
    });

}

export const GetDataWithoutToken = async (url)  => {
    let BaseUrl = globalVariable.baseURL + url;
    console.log('BaseUrl',BaseUrl)
    return await axios.get(BaseUrl,{
        headers: {
            "Content-Type": "application/json",
        },
    }).then(function (response) {
       //localStorage.setItem("token",response.headers['authorization'])
       //GetCommonData("/common/get-common-data");
       return response.data;
    })
    .catch(function (error) {
        if(error?.response?.data){
            return error.response.data
        }else{
            return error.response
        }
    });
}

export const PatchData = async (url,formData)  => {
    let token = defaultStorage('token')
    let BaseUrl = globalVariable.baseURL + url;
    return await axios.patch(BaseUrl,formData,{
        headers: {
            'Authorization': "Bearer "+token
        }
    }).then(function (response) {
        return response.data;})
    .catch(function (error) {
        if(error)
        {
            if(error.response)
            {
                if(error.response.status === 401){
                    localStorage.clear()
                    sessionStorage.clear();
                    window.location.href = '/';
                    return error.response.data
                }else{
                    return error.response.data
                }
            }
        }
        return false
    });
}

export const GetData = async (url,responseType="json")  => {
    let token = defaultStorage('token')
    // console.log('responseType',responseType)
    let BaseUrl = globalVariable.baseURL + url;
    return await axios.get(BaseUrl,{
        responseType:responseType,
        headers: {
            'Authorization': "Bearer "+token
        }
    }).then(function (response) {
        return response.data;})
    .catch(function (error) {
        if(error)
        {
            if(error.response)
            {
                if(error.response.status === 401){
                    localStorage.clear()
                    sessionStorage.clear();
                    window.location.href = '/';
                    return error.response.data
                }else{
                    return error.response.data ? error.response.data : error.response
                }
            }
        }
        return false
    });
}

export const GetCommonData = async () => {
    const BaseUrl = globalVariable.baseURL+'/user/institutions-country-common-api';
    return await axios.get(BaseUrl,{
        headers: {
            "Content-Type": "application/json"
        }
    }).then(function (response) {
        return response.data;
    })
}

export const PostData = async (url,formData)  => {
    const token = defaultStorage('token',true)
    let BaseUrl = globalVariable.baseURL + url;
    return await axios.post(BaseUrl,formData,{
        headers: {
            'Authorization': "Bearer "+token
        }
    }).then(function (response) {
        return response.data;
    })
    .catch(function (error) {
        if(error)
        {
            if(error.response)
            {
                if(error.response.status === 401){
                    localStorage.clear()
                    sessionStorage.clear();
                    window.location.href = '/';
                    return error.response.data
                }else{
                    return error.response.data
                }
            }
        }
        return false
    });
}

export const DeleteData = async (url)  => {
    const token = defaultStorage('token',true)
    let BaseUrl = globalVariable.baseURL + url;
    return await axios.delete(BaseUrl,{
        headers: {
            'Authorization': "Bearer "+token
        }
    }).then(function (response) {
        return response.data;})
    .catch(function (error) {
        return error.response
    });
}


export const PutData = async (url, token,formData)  => {
    token = defaultStorage('token',true)
    let BaseUrl = globalVariable.baseURL + url;
    return await axios.put(BaseUrl,formData,{
        headers: {
            'access_token': token
        }
    }).then(function (response) {
        return response.data;})
    .catch(function (error) {
        return error.response
    });
}


