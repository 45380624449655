let defaultStorage = (key,action=false) =>{
    if(action){
        if(sessionStorage.getItem(key)){
            return localStorage.getItem(key)
        }else{
            return localStorage.getItem(key)
        }
    }
    if(sessionStorage.getItem(key)){
        return sessionStorage.getItem(key)
    }else{
        return localStorage.getItem(key)
    }
}

export default defaultStorage