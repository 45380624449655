import React from 'react';

import Aux from "../../hoc/_Aux";

class PageSpinner extends React.Component {
    render() {
        /*const spinnerVariants = [
            'primary',
            'secondary',
            'success',
            'danger',
            'warning',
            'info',
            'light',
            'dark',
        ];*/

        /*const basicSpinnerGrowing = spinnerVariants.map((variant, idx) => (
            <div key={idx} className={['spinner-grow', 'mr-1', 'text-'+variant].join(' ')} role="status">
                <span className="sr-only">Loading...</span>
            </div>
        ));*/

        return (
            <Aux>
                {//basicSpinnerGrowing
                }
                {/* <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
                <div className="loaderOverlay"><img id="loading" src={require('../../assets/Logo.svg')} alt="mitr loader" /></div>
            </Aux>
        );
    }
}

export default PageSpinner;
