import cartReducer from './cartReducer'
import reducer from './reducer'
import notificationReducer from './notificationReducer'
import { combineReducers } from 'redux'


const rootReducer = combineReducers({
    cartReducer : cartReducer,
    defaultReducer : reducer,
    notificationReducer:notificationReducer
})

export default rootReducer