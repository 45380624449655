import React from 'react';
import './../../../assets/scss/style.scss';
import "bootstrap/dist/css/bootstrap.css"
import './../../../assets/scss/styleHawker.css';
import Aux from "../../../hoc/_Aux";
import {  GetDataWithoutToken } from '../../../services/apiRequestHandler'
import PageSpinner from '../../../App/components/PageSpinner';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../../../utils/constants';

class OtpVerification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            otp:"",
            otpError:"",
            seconds: 120,
            loading:false,
            showLogin:false,
            signInMsg:"",
        }
    }
    
    componentDidMount(){
        
        if(localStorage.getItem("token")){
            this.props.history.push("/dashboard");
        }else{
            this.setState({showLogin:true})
        }
         this.intervalId = setInterval(() => {
            this.setState(prevState => ({
                seconds: prevState.seconds > 0 ? prevState.seconds - 1 : 0
            }));
        }, 1000);
    }
    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

     formatTime(time) {
        const minutes = Math.floor(time / 60);
        const remainingSeconds = time % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    }
    handleResendOTP = () => {
        const { email } = this.props.location.state;
        
        this.setState({loading:true})
        GetDataWithoutToken(`/user/resend_code/?email=${email}`).then((response)=>{
            this.setState({ seconds: 120 });
                
                if(response?.status === true){
                    this.setState({loading:false})
                    console.log('resp',response)
                    this.notificationSystem.addNotification({
                        message: response?.message ? response?.message : 'Something went wrong. Please try again later or report to the administrator.',
                        level:  'info',
                    });
                }else{
                    this.setState({loading:false})
                    if(response?.message){ this.setState({passwordError:response?.message}) }
                    this.notificationSystem.addNotification({
                        message: response?.message ? response?.message : 'Something went wrong. Please try again later or report to the administrator.',
                        level:  'error',
                    });
                }
            })
        
    };
    fieldHandler = (e) => {
        const key = e.target.id;
        let regex = /^\S(?:.*\S)?$/;
        if(key === 'otp'){
           if(e.target.value && regex.test(e.target.value)){
             this.setState({otpError:"",}) 
            }
           else{ 
            console.log('this is invalid')    
            this.setState({otpError:"Please enter the code",})
         }
        }
        
         
         
        this.setState({[key]:e.target.value})
    }

    loginHandler = () => {
        this.setState({otpError:"",})
        const { email } = this.props.location.state;
        let error = 0;
        if(this.state.otp.includes(" ")){
            this.setState({otpError:"Please enter the valid code"})
            error = 1
        }
        else if(this.state.otp.trim() === ''){
            this.setState({otpError:"Please enter the code",})
            error = 1;
        }
       

        if(!error){
            this.setState({loading:true})
            GetDataWithoutToken(`/user/email-verify/?email=${email}&code=${this.state.otp}`).then((response)=>{
                
                if(response?.status === true){

                    console.log('response',response)

                    this.setState({loading:false,signInMsg:response?.message})
                    this.notificationSystem.addNotification({
                        message: response?.message ? <div dangerouslySetInnerHTML={{__html:response?.message}}/> : 'Something went wrong. Please try again later or report to the administrator.',
                        level:  'info',
                    });
                    setTimeout(()=>{
                        this.props.history.push("/login")
                    },5000)
                }else{
                    this.setState({loading:false})
                    if(response?.message){ this.setState({passwordError:response?.message}) }
                    this.notificationSystem.addNotification({
                        message: response?.message ? response?.message : 'Something went wrong. Please try again later or report to the administrator.',
                        level:  'error',
                    });
                }
            })
        }
    }

    loginOnEnter = (e) => {
        if (e.key === 'Enter' && this.state.otp.length ===6 ) {
            this.loginHandler();
        }
    }
    render () {
        const { email } = this.props.location.state || {};
        return(
            <Aux>
                {this.state.loading ? (
					<div className="fullScreenSpinner"><PageSpinner /></div>) : false}
                <div className="auth-wrapper aut-bg-img" >
                    <div className="auth-content">
                        <div>
                            
                            {
                            this.state.showLogin ? 
                                <div className="loginContainer">
                                    <div className="loginContainer-box">
                                        <div className="loginBox">
                                            <div className="loginBox-heading">
                                                <img src={require("../../../assets/Logo.svg")} alt="Logo" />
                                                <h1 className="welcomeText" style={{margin:"30px 0px 0px 0px"}}>Verify Your Email</h1>
                                                <p className="welcomeText">Please Enter the Code sent to your email to complete the registration process: 
                                               <p style={{color:'#18668f',fontSize:"1rem",fontWeight:"bold"}}>
                                                 {email ? email : null}
                                                </p>
                                                </p>
                                            </div>
                                             {
                                        this.state.signInMsg && 
                                        <div style={{ padding: '15px', background: '#fed469', marginBottom: '15px' }}>
                                            <p style={{ marginBottom: '0px', textAlign: 'center' }} dangerouslySetInnerHTML={{__html:this.state.signInMsg}}/>
                                        </div>
                                    }
                                            <div className="form-field">
                                                <input type="text" autoComplete="false" name="otp" id="otp" maxLength={6} onKeyUp={this.loginOnEnter} onChange={this.fieldHandler} value={this.state.otp} placeholder="Enter Code" />
                                                <div className="error">{this.state.otpError}</div>
                                            </div>
                                           
                                            <div className="form-field" style={{marginBottom:'15px'}}>
                                                <button onClick={this.loginHandler} 
                                                 disabled={this.state.otp.length !==6 && true} 
                                                //  className="customBtn theme-button"
                                                 className={`${this.state.otp.length !==6 ? "disabled-otp-btn" :"customBtn theme-button"}`}
                                                 >Submit</button>
                                            </div>
                                            <div className="bottomLinks">
                                                <div>Didn't receive code? 
                                                <button 
                                                className={`${this.state.seconds !== 0 ? "disabled-resend-btn": "resend-btn"}`}
                                                disabled={this.state.seconds !== 0}
                                                 onClick={this.handleResendOTP}
                                                  >Resend</button></div>
                                                <p>Resend OTP in: <span style={{color:"red"}}>
                                                    {this.formatTime(this.state.seconds)}
                                                    </span>
                                                    </p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="loginContainer-box">
                                            <div className="mobile-view">
                                                <img className="logo-image" src={require("../../../assets/Logo.svg")} alt="Logo" />
                                                <img src={require('../../../assets/tracking-our-global-heritage-mobile.jpg')} alt="Shark" />    
                                            </div>
                                            <div className="desktop-view">
                                                <img src={require('../../../assets/tracking-our-global-heritage.jpg')} alt="Shark" />
                                            </div>
                                    </div>
                                </div>
                            :
                            ""
                        }
                        </div>
                    </div>
                </div>
                <NotificationSystem
                    dismissible={false}
                    ref={notificationSystem =>
                        (this.notificationSystem = notificationSystem)
                    }
                    style={NOTIFICATION_SYSTEM_STYLE}
                />
            </Aux>
        );
    }
}

export default OtpVerification;