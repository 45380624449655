import * as ACTION_TYPES from './notificationActions'

const initialState = {
    totalNotifications:0
}

const notificationReducer = (state=initialState , action) => {
    switch(action.type){
        case ACTION_TYPES.NOTIFICATION_LIST:
            return{
                ...state,
                totalNotifications:action.payload.length,
            }
        case ACTION_TYPES.NOTIFICATION_COUNT:
            return{
                ...state,
                totalNotifications:action.payload,
            }
        
        default :
            return state
    }
}

export default notificationReducer